<template>
  <b-row>
    <b-col
      md="8"
      lg="8"
      xs="12"
    >
      <b-card>
        <b-row>
          <b-col
            v-if="$permissionAbility(LEAVE_BALANCE_VIEW_ALL, permissions)"
            md="6"
            lg="6"
            xs="6"
          >
            <v-select
              v-model="selectEmpId"
              :options="filterEmpIdOption"
              :reduce="(item) => item.id"
              label="name"
              placeholder="Select Employee"
              class="mb-1 custom-font"
              @input="loadItems"
            >
              <template #option="data">
                <UserSelect :user="data" />
              </template>
            </v-select>
          </b-col>
          <b-col
            v-if="selectEmpId && $permissionAbility(LEAVE_BALANCE_EDIT, permissions)"
            lg="6"
            md="6"
            xs="6"
            class="d-flex justify-content-end align-items-center"
          >
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="flex-shrink-0 mr-1"
                :variant="isEditable ? 'outline-primary' : 'primary'"
                @click="cancelEdit"
              >
                {{ isEditable ? 'Close' : 'Edit' }}
              </b-button>
            </template>

            <template v-if="isEditable">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="flex-shrink-0 mr-1"
                variant="primary"
                @click="saveBalance"
                :disabled="invalidIds.length > 0"
              >
                Save
              </b-button>
            </template>

          </b-col>
        </b-row>
        <div>
          <!-- table -->
          <vue-good-table
            :is-loading="isLoading"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"

            style-class="vgt-table striped"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: eligible_employee_types -->
              <div v-if="props.column.field === 'type'">
                <template v-if="props.row?.name">
                  <b>{{ props.row?.name }}</b>
                </template>
              </div>

              <div v-if="props.column.field === 'leave_allowance'">
                <template v-if="props.row && !isEditable">
                  <b-badge
                    class="ml-1"
                    variant="light-primary"
                  >
                    {{ props.row?.leave_allowance }}
                  </b-badge>
                </template>
                <template v-else-if="isEditable">
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                          :value="props.row?.leave_allowance"
                          placeholder="Input Number"
                          type="text"
                          :class="{ 'is-invalid': invalidIds.includes(props.row?.id) }"
                          class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
                          @input="setBalance(props.row?.id, $event)"
                      />
                      <small v-if="invalidIds.includes(props.row?.id)" class="text-danger">
                        Invalid Balance
                      </small>
                    </b-col>
                  </b-row>
                </template>
              </div>

              <div v-if="props.column.field === 'owned'">
                <b-badge
                  class="ml-1"
                  variant="light-danger"
                >
                  <template v-if="props.row?.leave_activity">
                    {{ props.row?.leave_activity?.leave_owned }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </b-badge>
              </div>

              <div v-if="props.column.field === 'format_remaining'">
                <b-badge
                  class="ml-1"
                  variant="light-success"
                >
                  <template v-if="props.row?.leave_activity">
                    {{ (props.row?.leave_allowance - props.row?.leave_activity?.leave_owned) <= 0 ? 0 : props.row?.leave_allowance - props.row?.leave_activity?.leave_owned }}
                  </template>
                  <template v-else>
                    {{ props.row?.leave_allowance }}
                  </template>
                </b-badge>
              </div>

            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
    </b-col>
    <b-col
        v-if="$permissionAbility(LEAVE_BALANCE_EDIT, permissions)"
      md="4"
      lg="4"
      xs="12"
    >
      <b-card-code title="Activity Log">

        <!-- Loading Spinner -->
        <template v-if="isLoading">
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner type="border" label="Loading..." />
          </div>
        </template>

        <template v-else>
          <div class="log-scrollable">
            <b-card><!-- Actual Content -->
              <app-timeline v-if="activityLog.length > 0">
                <template v-for="(item, index) in activityLog">
                  <app-timeline-item
                    :key="item?.id"
                    icon="InfoIcon"
                    variant="primary"
                    :title="`${item?.causer?.name} updated the '${item?.properties?.attributes?.leave_type}' balance for ${item?.subject?.name} from ${item?.properties?.old?.allowance} days to ${item?.properties?.attributes?.allowance} days`"
                    :time="formatDate(item?.created_at)"
                  />
                </template>
              </app-timeline>
              <template v-else>
                <h5 class="text-center">No Activity Log Found</h5>
              </template>
            </b-card>
          </div>
        </template>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, min } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  LEAVE_BALANCE_EDIT,
  LEAVE_BALANCE_VIEW_ALL,
} from '@/helpers/permissionsConstant'
import UserSelect from '@/layouts/components/UserSelect.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import BCardCode from '@core/components/b-card-code'
import { formatDate } from '@/helpers/helpers'

export default {
  name: 'LeaveBalanceView',
  components: {
    BCardCode,
    AppTimelineItem,
    AppTimeline,
    BCardActions,
    UserSelect,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      LEAVE_BALANCE_EDIT,
      LEAVE_BALANCE_VIEW_ALL,
      selectEmpId: '',
      isLoading: false,
      isEditable: false,
      filterEmpIdOption: [],
      activityLog: [],
      balanceData: [],
      invalidIds: [],
      pageLength: 10,
      columns: [
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Allowance',
          field: 'leave_allowance',
          sortable: false,
        },
        {
          label: 'Availed',
          field: 'owned',
          sortable: false,
        },
        {
          label: 'Remaining',
          field: 'format_remaining',
          sortable: false,
        },
      ],
      rows: [],
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  async created() {
    try {
      await Promise.all([this.loadItems()])

      const employees = await this.getEmployees()
      this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    formatDate,
    cancelEdit(){
      this.isEditable = !this.isEditable
      this.invalidIds = []
    },
    async loadActivityLog() {

      if(this.selectEmpId && this.$permissionAbility(LEAVE_BALANCE_EDIT, this.permissions)) {
        const [activityLogs] = await Promise.all([this.getActivityLog()])

        this.activityLog = activityLogs?.data?.data
      }else{
        this.activityLog = []
      }

    },
    async getActivityLog() {
      let url = 'api/leave/balance/activity'

      // Check if selectEmpId is available and append it to the URL
      if (this.selectEmpId) {
        url += `?user_id=${this.selectEmpId}`
      }

      return this.$api.get(url)
    },
    setBalance(id, value) {

      const numericValue = parseFloat(value);

      // Check if the value is not a valid number
      if (isNaN(numericValue) || !Number.isFinite(numericValue)) {

        if (!this.invalidIds.includes(id)) {
          this.invalidIds.push(id); // Mark as invalid
        }

        return; // Exit the function if the number is invalid
      }

      // Check if the value ends with .5
      if (numericValue % 1 !== 0.5 && numericValue % 1 !== 0) {
        if (!this.invalidIds.includes(id)) {
          this.invalidIds.push(id); // Mark as invalid
        }
        return; // Exit the function if the number is not a multiple of 0.5
      }

      // Remove the ID from invalidIds if the value is valid
      const invalidIndex = this.invalidIds.indexOf(id);
      if (invalidIndex !== -1) {
        this.invalidIds.splice(invalidIndex, 1); // Remove the invalid flag
      }


      // Find the index of the existing entry with the same id
      const index = this.balanceData.findIndex(item => item.id === id)

      if (index !== -1) {
        // If the id exists, update the balance value
        this.balanceData[index].balance = numericValue
      } else {
        // If the id does not exist, push a new entry
        this.balanceData.push({ id, balance: numericValue })
      }
    },
    async saveBalance() {

      if (!this.balanceData.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: 'No Changes Made',
          },
        })
        return // Exit the function early to avoid unnecessary API call
      }

      this.isEditable = !this.isEditable
      this.isLoading = true

      try {
        await this.$api.put('/api/leave/balance', {
          user: this.selectEmpId,
          data: this.balanceData ?? [],
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Balance Updated Successfully',
          },
        })

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      } finally {
        await this.loadItems()
        this.isLoading = false
      }
    },
    async getEmployees() {
      return await this.$api.get('api/users/active-all')
    },
    async getLeaveTypes() {
      let url = 'api/leave/balance'

      // Check if selectEmpId is available and append it to the URL
      if (this.$permissionAbility(LEAVE_BALANCE_VIEW_ALL, this.permissions)) {
        if (this.selectEmpId) {
          url += `?user_id=${this.selectEmpId}`
        }
      }

      return await this.$api.get(url)
    },

    async loadItems() {
      try {
        this.isEditable = false
        this.isLoading = true
        this.balanceData = []

        const [leaveTypes] = await Promise.all([
          this.getLeaveTypes(),
          this.loadActivityLog(),
        ])

        const data = leaveTypes?.data?.data
        this.rows = data
        this.isLoading = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.log-scrollable {
  max-height: 500px;
  overflow-y: auto;
}

</style>
